import {useMutation} from "@apollo/react-hooks";
import Drawer from "@material-ui/core/Drawer";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {CreateCSSProperties} from "@material-ui/styles";
import {gql} from "apollo-boost";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {GET_OPTIONS} from "../admin/JobsOverview";
import {Option, SubOption} from "../../apollo/generated";
import {useEffect, useState} from "react";
import * as React from 'react'

const UPDATE_OPTION = gql`
  mutation UpdateOption($id: String!, $description: String!) {
    updateOption (id: $id, description: $description) {
      id
      description
      subOptions {
        id
        description
        linksTo
        trades {
          id
          group
        }
      }
    }
  }
`

const detailsWidth = 350;

const useStyles = makeStyles(theme => {
  const marginBottom = theme.spacing(2)
  const drawerPaper: CreateCSSProperties = {
    width: detailsWidth,
    backgroundImage: 'linear-gradient(135deg, #2c3e50 0%, #2c3e50 100%)',
    color: 'white',
    padding: '1.5rem'
  };

  return {
    titleWrapper: {
      display: 'flex',
      alignContent: "center",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom
    },
    title: {
      fontWeight: 'bold',
      fontSize: '16px',
    },
    description: {
      marginBottom
    },
    subTitle: {
      fontWeight: 'bold',
      marginBottom
    },
    subOption: {
      marginBottom: theme.spacing(1)
    },
    trades: {
      fontStyle: "italic"
    },
    input: {
      color: "white",
      borderBottom: '2px solid rgb(255, 255, 255) !important',
    },
    inputWrapper: {
      marginBottom
    },
    inputLabel: {
      color: "white",
    },
    drawer: {
      width: detailsWidth,
      flexShrink: 0,
    },
    drawerPaper,
    drawerPaperEdit: {
      ...drawerPaper,
      width: detailsWidth * 2,
    },
  };
});

interface OptionEditProps {
  option: Option;
  usageCount: number
}

const OptionEdit: React.FC<OptionEditProps> = ({option, usageCount}) => {
  const [description, setDescription] = useState<string>('');
  const [edit, setEdit] = useState<boolean>(false);
  const [updateOption] = useMutation(UPDATE_OPTION, {
    refetchQueries: [{query: GET_OPTIONS}]
  });

  const classes = useStyles();

  useEffect(() => {
    if (option.description && !description) {
      setDescription(option.description)
    }
  }, [option])

  const handleDescriptionChange = e => setDescription(e.target.value);
  const handleCancel = () => {
    setDescription(option.description);
    setEdit(false);
  }
  const handleSave = async () => {
    await updateOption({variables: {id: option.id, description}})
    setEdit(false);
  };

  const getTrades = ({trades}: SubOption) => (!trades || trades.length === 0) ? '' : trades.map(t => t.group).join(', ');

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{paper: classes.drawerPaper}}
      anchor="right"
    >
      {edit ? (
        <div className={classes.inputWrapper}>
          <TextField
            label="Description"
            value={description}
            onChange={handleDescriptionChange}
            InputLabelProps={{
              className: classes.inputLabel
            }}
            inputProps={{
              className: classes.input
            }}
          />
          <button onClick={handleSave}>Save</button>
          <button onClick={handleCancel}>Cancel</button>
        </div>
      ) : (
        <div className={classes.titleWrapper}>
          <Typography className={classes.title}>
            {description}
          </Typography>
          <IconButton color="primary" size="small" onClick={() => setEdit(true)}>
            <EditIcon/>
          </IconButton>
        </div>
      )}
      <Typography className={classes.subTitle}>
        SubOptions:
      </Typography>
      {
        option.subOptions.map((subOption) => {
          const trades = getTrades(subOption);
          return (
            <div key={subOption.id} className={classes.subOption}>
              <Typography>
                {subOption.description}
              </Typography>
              <Typography className={classes.trades}>
                Trades: {trades ? trades : 'none'}
              </Typography>
            </div>
          )
        })
      }
    </Drawer>
  )
}

export default OptionEdit
