import * as React from 'react'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler';
import AuthGuard from '../../components/ui/AuthGuard';
import AdminSidebar from '../../components/adminSidebar';
import AdminJobs from '../../components/admin/JobsOverview';

const AdminUsersScreen = () => (
  <DisastrousErrorHandler>
    <AuthGuard allowed="ADMIN">
      <AdminSidebar>
        <AdminJobs/>
      </AdminSidebar>
    </AuthGuard>
  </DisastrousErrorHandler>
);

export default AdminUsersScreen
