import {useMutation} from "@apollo/react-hooks";
import Drawer from "@material-ui/core/Drawer";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {gql} from "apollo-boost";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {GET_OPTIONS} from "../admin/JobsOverview";
import {SubOption} from "../../apollo/generated";
import {useEffect, useState} from "react";
import * as React from 'react'

const UPDATE_SUB_OPTION = gql`
  mutation UpdateSubOption($id: String!, $description: String!) {
    updateSubOption (id: $id, description: $description) {
      id
      description
    }
  }
`

const detailsWidth = 350;

const useStyles = makeStyles(theme => {
  const marginBottom = theme.spacing(2)

  return {
    titleWrapper: {
      display: 'flex',
      alignContent: "center",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom
    },
    title: {
      fontWeight: 'bold',
      fontSize: '16px',
    },
    description: {
      marginBottom
    },
    used: {
      marginBottom
    },
    subTitle: {
      fontWeight: 'bold',
      marginBottom
    },
    subSubOption: {
      marginBottom: theme.spacing(1)
    },
    trades: {
      fontStyle: "italic"
    },
    input: {
      color: "white",
      borderBottom: '2px solid rgb(255, 255, 255) !important',
    },
    inputWrapper: {
      marginBottom
    },
    inputLabel: {
      color: "white",
    },
    drawer: {
      width: detailsWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: detailsWidth,
      backgroundImage: 'linear-gradient(135deg, #2c3e50 0%, #2c3e50 100%)',
      color: 'white',
      padding: '1.5rem'
    },
  };
});

interface SubOptionEditProps {
  subOption: SubOption;
  usageCount: number
}

const SubOptionEdit: React.FC<SubOptionEditProps> = ({subOption, usageCount}) => {
  const [description, setDescription] = useState<string>('');
  const [edit, setEdit] = useState<boolean>(false);
  const [updateSubOption] = useMutation(UPDATE_SUB_OPTION, {
    refetchQueries: [{query: GET_OPTIONS}]
  });

  const classes = useStyles();

  useEffect(() => {
    if (subOption.description && !description) {
      setDescription(subOption.description)
    }
  }, [subOption])

  const handleDescriptionChange = e => setDescription(e.target.value);
  const handleCancel = () => {
    setDescription(subOption.description);
    setEdit(false);
  }
  const handleSave = async () => {
    await updateSubOption({variables: {id: subOption.id, description}})
    setEdit(false);
  };

  const getTrades = (trades) => (!trades || trades.length === 0) ? '' : trades.map(t => t.group).join(', ');

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{paper: classes.drawerPaper}}
      anchor="right"
    >
      {edit ? (
        <div className={classes.inputWrapper}>
          <TextField
            label="Description"
            value={description}
            onChange={handleDescriptionChange}
            InputLabelProps={{
              className: classes.inputLabel
            }}
            inputProps={{
              className: classes.input
            }}
          />
          <button onClick={handleSave}>Save</button>
          <button onClick={handleCancel}>Cancel</button>
        </div>
      ) : (
        <div className={classes.titleWrapper}>
          <Typography className={classes.title}>
            {description}
          </Typography>
          <IconButton color="primary" size="small" onClick={() => setEdit(true)}>
            <EditIcon/>
          </IconButton>
        </div>
      )}
      <Typography className={classes.subTitle}>
        Trades:
      </Typography>
      <Typography className={classes.subTitle}>
        {subOption.trades && getTrades(subOption.trades)}
      </Typography>
    </Drawer>
  )
}

export default SubOptionEdit
