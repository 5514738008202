import {makeStyles} from "@material-ui/core/styles";
import {TreeItem} from "@material-ui/lab";
import TreeView from "@material-ui/lab/TreeView";
import {gql} from "apollo-boost";
import SubOptionEdit from "./SubOptionEdit";
import OptionEdit from "./OptionEdit";
import {Option, SubOption} from "../../apollo/generated";
import {useEffect, useState} from "react";
import * as React from 'react'
import Loading from '../ui/Loading';
import {useQuery} from '@apollo/react-hooks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const GET_OPTIONS = gql`
  {
    options {
      id
      description
      subOptions {
        id
        description
        linksTo
        option {
          id
        }
        trades {
          id
          group
        }
      }
    }
  }
`

const detailsWidth = 350;

const useStyles = makeStyles({
  root: {
    height: '100%',
    overflow: 'auto'
  },
  drawer: {
    width: detailsWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: detailsWidth,
    backgroundImage: 'linear-gradient(135deg, #2c3e50 0%, #2c3e50 100%)',
    color: 'white',
    padding: '1.5rem'
  },
  drawerPaperEdit: {
    width: detailsWidth * 2,
    backgroundImage: 'linear-gradient(135deg, #2c3e50 0%, #2c3e50 100%)',
    color: 'white',
    padding: '1.5rem'
  },
});

const isOption = (option: Option | SubOption): option is Option =>
  (option as Option).subOptions !== undefined;

const JobsOverview = () => {
  const {data, loading} = useQuery(GET_OPTIONS);
  const [rootOptions, setRootOptions] = useState<Option[]>();
  const [subOptions, setSubOptions] = useState<SubOption[]>();
  const [selectedItem, setSelectedItem] = useState<Option | SubOption>();
  const classes = useStyles();

  useEffect(() => {
    if (data && data.options) {
      const subOptions = data.options.reduce((result, option) => {
        return [...result, ...option.subOptions]
      }, [])
      const baseOptions = data.options.filter(option => !subOptions.find(s => s.linksTo === option.id))

      setRootOptions(baseOptions);
      setSubOptions(subOptions);
    }
  }, [data])

  if (loading || !rootOptions) return <Loading/>;

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string) => {
    const idsArray = nodeIds.split('-');
    const currentItemId = idsArray.pop();
    const option = data.options.find(option => option.id === currentItemId);

    if (option) {
      setSelectedItem(option);
    } else {
      const subOption = subOptions.find(subOption => subOption.id === currentItemId);
      setSelectedItem(subOption);
    }
  };

  const renderOption = (id: string, parentId?: string) => {
    const option = data.options.find(o => o.id === id);
    return option ? renderTree({...option}, parentId) : null;
  }

  const renderTree = ({id, description, subOptions}: Option, parentId?: string) => {
    //Create unique id to prevent opening multiple nodes
    const treeId = parentId ? `${parentId}-${id}` : id;
    return (
      <TreeItem
        key={treeId}
        nodeId={treeId}
        label={description}
      >
        {subOptions && subOptions.map(subOption => renderSubTree(subOption, treeId))}
      </TreeItem>
    );
  }

  const getTradesText = trades => (!trades || trades.length === 0) ? '' : `(${trades.map(t => t.group).join(', ')})`;

  const renderSubTree = ({id, description, linksTo, trades}: SubOption, treeId: string) => {
    const subTreeId = `${treeId}-${id}`;
    return (
      <TreeItem
        key={subTreeId}
        nodeId={subTreeId}
        label={`${description} ${getTradesText(trades)}`}
      >
        {renderOption(linksTo, subTreeId)}
      </TreeItem>
    )
  }

  return (
    <div className={classes.root}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon/>}
        defaultExpandIcon={<ChevronRightIcon/>}
        onNodeSelect={handleSelect}
      >
        {rootOptions.map(option => renderTree(option))}
      </TreeView>
      {selectedItem && isOption(selectedItem) && (
        <OptionEdit option={selectedItem} />
      )}
      {selectedItem && !isOption(selectedItem) && (
        <SubOptionEdit subOption={selectedItem} />
      )}
    </div>
  );
}

export default JobsOverview
